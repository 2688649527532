import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Main from './components/main/Main';
import About from './components/about/About';
import ServiceDetails from './components/services/ServiceDetails';
import ServiceGrid from './components/services/ServiceGrid';
import Contact from './components/contact/Contact';
import Privacy from "./components/privacy/Privacy";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<Main />} />
          <Route path="/services" element={<ServiceGrid />} />
          <Route path="/about" element={<About />} />
          {/* <Route path='/contact' element={<ContactUs />} /> */}
          <Route path="/privacy" element={<Privacy />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/services/service-details' element={<ServiceDetails />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
