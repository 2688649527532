import React from 'react'
import './Privacy.css'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import { Collapse } from 'antd'
const { Panel } = Collapse;

const Privacy = () => {
    return (
        <div>
            <Header />
            <div style={{ padding: "20px", maxWidth: "1000px", margin: "auto" }}>
                <h4>Privacy Policy for Customers and Drivers</h4>
                <Collapse defaultActiveKey={["1"]} accordion>
                    <Panel header="1. Personal data we process" key="1" className='privacy-txt'>
                        <p className='first-title'>
                            We only process the essential information required to provide you with reliable and efficient transportation services for your agricultural goods. At Maxon, safeguarding your data ensures a seamless experience for farmers, traders, and businesses.
                        </p>
                        <ul className="custom-list">
                            <li>
                                <strong>Contact details:</strong>  Name, phone number, and email address to facilitate communication about your transportation requests.
                            </li>
                            <li>
                                <strong>Profile information:</strong> Saved addresses, profile picture, language preferences, and communication settings to optimize your experience on our platform.
                            </li>
                            <li>
                                <strong>Geolocation:</strong>To match you with nearby drivers and provide accurate real-time tracking of your goods.
                            </li>
                            <li>
                                <strong>Payment information:</strong> Credit card details or other payment methods to process amount charged for secure and transparent transactions.
                            </li>
                            <li>
                                <strong>Device identification data:</strong> IP address of the device where the app is installed to maintain secure access and prevent fraud.
                            </li>
                            <li>
                                <strong>Service usage data:</strong> Journey statuses, trip history, feedback, and ratings to improve service quality and efficiency.
                            </li>
                        </ul>
                    </Panel>

                    <Panel header="2. Purpose of the processing" key="2" className='privacy-txt'>
                        <p className='first-title'>
                            At Maxon, we collect and process your personal data to deliver efficient agricultural goods transportation services:
                        </p>
                        <ul className="custom-list">
                            <li><strong>Connecting You with Drivers and Vehicles:</strong>We collect and process personal data to connect you with reliable drivers or help you find available vehicles, such as trucks and cargo scooters, to transport your farm produce. This ensures timely and secure deliveries. Communication via the Maxon platform helps with customer support, resolving disputes, enhancing security, and improving our services.</li>
                            <li>
                                <storng>Ensuring Smooth Deliveries:</storng>
                                We use geolocation data to assist drivers with accurate pick-up and drop-off locations, making your transportation journey as efficient as possible. This data is collected only when the Maxon app is active and stops once you close the app. In some regions, the phone number of the recipient may be masked for privacy, allowing communication through a temporary phone number.
                            </li>
                            <li>
                                <strong>Optimizing Fleet Operations:</strong>
                                We utilize geolocation data to monitor your deliveries and resolve any service-related issues. Knowing the destination of your cargo helps us manage our fleet, ensuring timely pick-ups and drop-offs, as well as performing vehicle maintenance.
                            </li>
                            <li>
                                <strong>Ensuring App Performance:</strong>
                                We use your contact details to notify you about updates to the Maxon app, ensuring you can always access our services. Additionally, we collect basic device data to maintain security and authenticate your account.
                            </li>
                            <li>
                                <strong>Optimizing Routes and Fleet Locations:</strong>
                                By analyzing data on delivery routes, we enhance our service by recommending the most efficient routes for drivers and placing hire-vehicles in strategic locations to meet your needs.
                            </li>
                            <li>
                                <strong>Payment Processing:</strong>
                                We collect and process payment information for services rendered, whether it's for transporting agricultural goods or renting vehicles such as trucks or cargo scooters.
                            </li>
                            <li>
                                <strong>Customer Support:</strong>
                                We use your contact details to provide customer support, resolve disputes, and address any issues you may encounter during your journey.
                            </li>
                            <li>
                                <strong>Maintaining Service Quality:</strong>
                                We gather data on journey statuses, delivery times, and feedback to uphold high service standards, ensuring compliance with our terms and conditions and resolving any service quality issues.
                            </li>
                            <li>
                                <strong>Keeping You Updated:</strong>
                                Your name, phone number, and email address help us communicate important updates regarding your deliveries, send you receipts, and notify you of any relevant service changes, such as fleet availability or disruptions.
                            </li>
                        </ul>
                    </Panel>

                    <Panel header="3. Legal Basis" key="3" className='privacy-txt'>
                        <p className='first-title'>
                            Maxon is permitted to process your personal data in the ways described above when we have a valid reason to do so. We ensure that we always have a legitimate basis for handling your data.
                        </p>
                        <ul className="custom-list">
                            <li>
                                <strong>Contractual Necessity:</strong>
                                Personal data is processed primarily to provide the services we offer, such as transportation of agricultural goods. In order to fulfill the services you’ve contracted through the Maxon platform and to meet our terms and conditions, we process your data to ensure we deliver on our promises to you.
                            </li>
                            <li>
                                <strong>Legal Obligations:</strong>
                                We may process your data to comply with legal requirements, such as tax obligations, accounting standards, or responding to lawful requests from authorities.
                            </li>
                            <li>
                                <strong>Legitimate Interests:</strong>
                                We process your data to meet our legitimate interests, such as optimizing our services, ensuring security, preventing fraud, and enhancing user experience. We also process data to maintain and improve our platform, manage our fleet, and provide customer support.
                            </li>
                            <li>
                                <strong>Legal Obligation:</strong>
                                We may need to process your data to comply with legal obligations, such as when required by law enforcement or regulatory authorities.
                            </li>
                            <li>
                                <strong>Consent:</strong>
                                In some cases, we may rely on your consent to process your data, such as when you agree to receive marketing communications from us. You can withdraw your consent at any time by updating your communication preferences in the Maxon app.
                            </li>
                            <li>
                                <strong>Protection of Vital Interests:</strong>
                                In some situations, we may process your data to protect your vital interests or those of others, such as in emergencies or life-threatening situations.
                            </li>
                        </ul>
                    </Panel>

                    <Panel header="4. Recipients" key="4" className='privacy-txt'>
                        <p className='first-title'>
                            At Maxon, we prioritize working with trusted partners and authorities. We only share your personal data when there is a legitimate reason to do so, and we limit the sharing to what is absolutely necessary. We do not sell your personal information.
                        </p>
                        <ul className="custom-list">
                            <li>
                                <strong>Sharing Data with Drivers:</strong>
                                Your personal data, such as your name, phone number (which may be masked in certain regions), and geolocation, will only be shared with the driver when you book transportation for your agricultural goods via the Maxon platform. This allows the driver to connect with you for efficient pick-up and delivery.
                            </li>
                            <li>
                                <strong>Post-Service Contact:</strong>
                                After your journey is complete, we may share your contact details with the driver. This enables the driver to contact you in case you leave any items behind or if there are any issues with the delivery.
                            </li>
                            <li>
                                <strong></strong>
                            </li>
                            <li>
                                <strong>
                                    FeedBack and Ratings:
                                </strong>
                                After your journey, we may ask you to provide feedback and ratings on the driver and the service. Your feedback regarding the service quality and the driver's performance is anonymous and helps us improve our services
                            </li>
                            <li>
                                <strong>Legal Obligations:</strong>
                                We may share your personal data with authorities or law enforcement agencies when required by law or in response to a lawful request. If we are legally obligated to share your data, we will ensure that the request is in line with relevant laws and international standards, and that we document the decision carefully.
                            </li>
                        </ul>
                    </Panel>

                    <Panel header="5. Security and Access" key="5" className='privacy-txt'>
                        <p className='first-title'>
                            At Maxon, the security of your personal data is a top priority. Any personal data collected during the provision of our services is transferred and stored securely in data centers operated by trusted providers, such as Amazon Web Services (AWS), and other reliable partners. These data centers are located in regions that comply with applicable data protection regulations.
                        </p>
                        <ul className="custom-list">
                            <li>
                                <strong>Security Measures:</strong>
                                We implement robust security measures to protect your personal data from unauthorized access, disclosure, alteration, or destruction. These measures include encryption, access controls, and regular security assessments.
                            </li>
                            <li>
                                <strong>Access Controls:</strong>
                                Access to your personal data is restricted to authorized personnel who require the information to perform their duties. We maintain strict access controls and monitor access to your data to prevent unauthorized use.
                            </li>
                            <li>
                                <strong>Incident Response:</strong>
                                In the event of a data breach or security incident, we have procedures in place to respond promptly, investigate the incident, and take appropriate action to mitigate the impact on your personal data.
                            </li>
                            <li>
                                <strong>Third-Party Services:</strong>
                                When we use third-party services to process your data, we ensure that they meet our security standards and comply with relevant data protection laws. We carefully select our partners and regularly review their security practices.
                            </li>
                            <li>
                                <strong>Anonymization for Research</strong>
                                For research, analysis, and improvement of our services, we anonymize data, such as bulk geolocation data. This means that any data used for these purposes will be stripped of personal identifiers, ensuring that you cannot be identified from this data.
                            </li>
                        </ul>
                    </Panel>

                    <Panel header="6. Your Rights and Controls" key="6" className='privacy-txt'>
                        <p className='first-title'>
                            At Maxon, we respect your privacy and provide you with control over your personal data. You have certain rights regarding the information we collect and how we use it. These rights help you manage your data and ensure that you have control over your experience with our services.
                        </p>
                        <ul className="custom-list">
                            <li>
                                <strong>Access to Your Data:</strong>
                                You have the right to request access to the personal data we hold about you. This means you can review, update, or correct any information we have stored. If you'd like to access your data, you can contact us through our support channels.
                            </li>
                            <li>
                                <strong>Correction and Deletion:</strong>
                                If any of your personal data is inaccurate or incomplete, you have the right to request that we correct it. You can also ask us to delete your personal data when it's no longer necessary for the purposes for which it was collected or if you withdraw your consent.
                            </li>
                            <li>
                                <strong>Data Portability:</strong>
                                You have the right to request a copy of your personal data in a structured, commonly used, and machine-readable format. You can use this data to transfer it to another service provider if desired.
                            </li>
                            <li>
                                <strong>Withdraw Consent:</strong>
                                If you have provided consent for us to process your data (e.g., for marketing purposes), you can withdraw that consent at any time. This will not affect the lawfulness of any processing carried out before you withdrew your consent.
                            </li>
                            <li>
                                <strong>Objection to Processing:</strong>
                                You have the right to object to the processing of your personal data in certain circumstances, such as when we process your data for direct marketing purposes. If you object to the processing, we will stop processing your data for that purpose.
                            </li>
                            <li>
                                <strong>Restriction of Processing:</strong>
                                You can request that we restrict the processing of your personal data in certain situations, such as when you contest the accuracy of the data or when you object to the processing.
                            </li>
                            <li>
                                <strong>Lodge a Complaint</strong>
                                If you feel that your data protection rights have been violated, you can file a complaint with the relevant data protection authority in your country. We encourage you to contact us first, and we will work to resolve any concerns you may have.
                            </li>
                        </ul>
                    </Panel>

                    <Panel header="7. Retention" key="7" className='privacy-txt'>
                        <p className='first-title'>
                            At Maxon, we only retain your personal data for as long as necessary to fulfill the purposes for which it was collected and to comply with legal or regulatory requirements. The retention period depends on the nature of the data and the services we provide.
                        </p>
                        <ul className="custom-list">
                            <li>
                                <strong>Service-Related Data:</strong>
                                We retain your personal data for as long as you use our services or for as long as required t provide customer support, resolve disputes, or comply with applicable legal obligations. If you stop using our services, we will retain your data for a reasonable period to ensure that we can meet any remaining obligations.
                            </li>
                            <li>
                                <strong>Transaction Data:</strong>
                                For financial transactions, such as payments for transportation services, we will retain transaction records for the period required by law for tax, audit, and financial reporting purposes. This is generally up to 7 years, depending on the jurisdiction and applicable regulations.
                            </li>
                            <li>
                                <strong>Geolocation Data</strong>
                                We retain your geolocation data only for the duration necessary to complete your transportation request and related services. After the transportation service is completed, this data will no longer be stored, except where required by law or for dispute resolution purposes.
                            </li>
                            <li>
                                <strong>Communication and Support Data:</strong>
                                Any personal data collected through customer support interactions, including emails, phone calls, and chats, will be retained for as long as necessary to resolve issues and improve our services. We may keep records of customer support interactions for up to 3 years or as required by law.
                            </li>
                            <li>
                                <strong>Legal and Regulatory Requirements:</strong>
                                In certain cases, we may need to retain personal data for longer periods to comply with legal obligations, resolve disputes, enforce agreements, or comply with regulatory requirements.
                            </li>
                        </ul>
                    </Panel>

                    <Panel header="8. Direct Marketing" key="8" className='privacy-txt'>
                        <p className='first-title'>
                            At Maxon, we value your privacy and aim to provide services that are relevant to your needs. From time to time, we may use your personal data to send you marketing communications related to our transportation services, promotions, updates, and new features that we believe may be of interest to you.
                        </p>
                        <p className='first-title'>
                            However, we are committed to respecting your preferences, and you have full control over how we communicate with you. Here’s how we manage direct marketing:
                        </p>
                        <ul className="custom-list">
                            <li>
                                <strong>What we May Send:</strong>
                                We may send you promotional emails, push notifications, SMS messages, or other forms of communication to inform you about special offers, discounts, new service features, or important updates related to the Maxon platform and your transportation needs.
                            </li>
                            <li>
                                <strong>Option Out:</strong>
                                If you no longer wish to receive marketing communications, you can opt out at any time. You can unsubscribe from email newsletters through the unsubscribe link provided in each email, or you can adjust your communication preferences in your account settings within the Maxon app. For SMS or push notifications, you can opt out by following the instructions provided in the message or by adjusting your preferences in the app.
                            </li>
                            <li>
                                <strong>Legal Basis for Marketing:</strong>
                                We process your personal data for direct marketing purposes based on our legitimate interest in promoting and improving our services. However, you have the right to object to the use of your data for direct marketing at any time. If you object, we will cease to send you marketing messages, and your request will be respected.
                            </li>
                        </ul>
                    </Panel>
                </Collapse>
            </div>
            <Footer />
        </div>
    )
}

export default Privacy